import AllLanguages from "../LegalTranscription/Languages/AllLanguages";
import React from "react";
import Layout from "../components/Layout";
import Seo from "../components/seo";
import Banner from "../LegalTranscription/Home/Banner";
import CtaWithForm from "../LegalTranscription/Home/CtaWithForm";
import { useLocation } from "@reach/router";

import {
  languagesBannerData,
  languagesCtaWithFormData,
  availableLanguagesData,
} from "../LegalTranscription/utils/sample-data";
import BreadCrumb from "../components/BreadCrumb";

function Index() {
  const location = useLocation();
  return (
    <Layout>
      <Seo
        title="Language Translation Services - FormalTranslations.com"
        description=""
        slug="languages/"
      />
      <Banner data={languagesBannerData} background="bg-midSlate" />
      <BreadCrumb path={location.pathname} />
      <AllLanguages data={availableLanguagesData} background="bg-[#F5F5F5]" />
      <CtaWithForm
        data={languagesCtaWithFormData}
        background="bg-[#5927B0]"
        btnStyle="bg-black hover:bg-black"
      />
    </Layout>
  );
}
export default Index;
